import { ref } from 'vue';

import { fileMutation } from '@/apollo/mutations';
import { $apollo } from '@/plugins/vue-apollo';

export const fileLoading = ref(false);

export async function imageUpload({ image, mimetype, size = 'M' }) {
  try {
    fileLoading.value = true;
    const { data } = await $apollo.mutate<{ imageUpload: { url: string } }>({
      mutation: fileMutation.imageUpload,
      variables: {
        param: {
          image,
          mimetype,
          size,
        },
      },
    });

    return data?.imageUpload.url ?? '';
  } catch (err) {
    return '';
  } finally {
    fileLoading.value = false;
  }
}

export function loadImage(url: string): Promise<HTMLImageElement> {
  fileLoading.value = true;
  return new Promise((reslove) => {
    const image = new Image();
    image.src = url;

    if (image.complete) {
      fileLoading.value = false;
      // 圖片已經被載入
      reslove(image);
    } else {
      // 如果圖片未被載入，則設定載入時的回調
      image.onload = () => {
        fileLoading.value = false;
        reslove(image);
      };
    }
  });
}
